*::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    cursor: pointer;
}

*::-webkit-scrollbar-track {
    background: #1a1718;
    height: 5px;
    width: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: #252122;
    border-radius: 20px;
    border: 1px solid #252122;
}

.ant-drawer-content-wrapper {
    width: 30vw !important;
}


@media screen and (max-width: 1600px) {
    .ant-drawer-content-wrapper {
        width: 50vw !important;
    }
}


@media screen and (max-width: 1000px) {
    .ant-drawer-content-wrapper {
        width: 75vw !important;
    }
}


@media screen and (max-width: 700px) {
    .ant-drawer-content-wrapper {
        width: 100vw !important;
    }
}

video {
    background: #000;
}
