.episode {
    width: 260px;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
}

.episode-active {
    border-color: #a53ba6;
    color: #a53ba6;
    background-color: #1a1718;
}

.episode:hover {
    border-color: #44cfdb;
    color: #44cfdb;
    background-color: #1a1718;
}
.series-carousel {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-color: #a53ba6 transparent;
    scrollbar-width: thin;
    padding-bottom: 10px;
}

.series-carousel-item {
    margin-right: 15px;
}

.series-carousel-item:nth-last-child(1) {
    margin-right: 0;
}

.series-carousel::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    cursor: pointer;
}

.series-carousel::-webkit-scrollbar-track {
    background: #252122;
    height: 5px;
    width: 5px;
}

.series-carousel::-webkit-scrollbar-thumb {
    background-color: #1a1718;
    border-radius: 20px;
    border: 1px solid #1a1718;
}
