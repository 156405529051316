.layout {
    min-height: 100vh;
}

.header {
    background: #252122;
    margin-bottom: 25px;
}

.spin {
    margin-top: 40vh;
}

@media screen and (max-width: 700px) {
    .ant-layout-header {
        padding: 0 15px;
    }
}
