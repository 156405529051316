.favorite-title {
    text-align: center;
    margin-top: 0;
}

.category-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.category-card {
    margin: 15px;
}

