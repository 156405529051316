.video-js {
    background: #000 !important;
}

.vjs-custom {
    position: relative !important;
    background-color: #1a1718 !important;
    outline: none !important;
    border: none !important;
}

.vjs-custom video {
    outline: none !important;
    border: none !important;
}

.vjs-menu-button-popup .vjs-menu {
    width: 15em;
    left: -5em;
}

.vjs-texttrack-settings {
    display: none;
}

.vjs-subs-caps-button {
    display: none;
}

.vjs-custom .vjs-play-progress {
    background-color: #a53ba6 !important;
}

.vjs-custom .vjs-control-bar {
    background-color: #1a1718 !important;
}

.vjs-next-button {
    border: 1px solid #a53ba6 !important;
    color: #fff !important;
    background-color: #a53ba6 !important;
    position: absolute !important;
    right: 10px;
    bottom: 150%;
    cursor: pointer;
    width: 60px !important;
    height: 30px !important;
    border-radius: 4px;
}

.vjs-next-button-next {
    right: 10px !important;
}

.vjs-next-button-prev {
    left: 10px !important;
}

.vjs-next-button .vjs-control-text {
    width: 60px !important;
    height: 30px !important;
    line-height: 28px;
    top: 0;
    left: 0;
    color: #fff !important;
    clip: unset !important;
    font-size: 12px;
}

.vjs-next-button .vjs-icon-placeholder {
    display: none !important;
}

.vjs-next-button-show {
    display: block !important;
}

.vjs-next-item-control, .vjs-previous-item-control {
    font-family: VideoJS;
    font-weight: normal;
    font-style: normal;
    cursor: pointer;
}

.vjs-next-item-control:before {
    content: "\f123";
}

.video-js .vjs-next-item-control .vjs-icon-placeholder:before {
    content: "\f123";
}

.video-js .vjs-previous-item-control .vjs-icon-placeholder:before {
    content: "\f124";
}

.video-js .vjs-progress-control {
    position: absolute !important;
    width: 100% !important;
    align-items: end !important;
    top: -20px;
    height: 20px !important;
}

.video-js .vjs-progress-control .vjs-progress-holder {
    margin: 0 !important;
}

.control-bar-child-left {
    display: flex;
    flex-direction: row;
}

.vjs-control-bar {
    display: flex;
    justify-content: space-between;
}


.vjs-control-bar .vjs-spacer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.vjs-control-hidden {
    display: none;
}
