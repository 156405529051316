.card {
    height: 100%;
}

.card > div {
    width: 100%;
    display: flex;
    height: 100%;
}

.card-title {
    font-size: 20px;
    line-height: 20px;
    width: 100%;
}

.card-box {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.card-content > div {
    display: inline-flex !important;
}

.card-image img {
    width: 260px !important;
    height: 364px !important;
}

.card-body {
    margin-top: 10px;
    width: 260px !important;
    min-height: 70px;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}

.card-body-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.card-desc {
    margin: 0;
}
