.film-title {
    margin-top: 0 !important;
    font-size: 30px !important;
}

.episode {
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: pointer;
}

.episode:hover {
    border-color: #a53ba6;
    color: #a53ba6;
    background-color: #1a1718;
}

.film-carousel {
    display: flex;
    flex-wrap: nowrap;
    scrollbar-color: #a53ba6 transparent;
    scrollbar-width: thin;
    padding-bottom: 10px;
    flex-direction: row;
}

.film-carousel-item {
    margin-right: 15px;
    min-width: 260px;
    display: flex;
    flex-direction: column;
}

.film-carousel-item:nth-last-child(1) {
    margin-right: 0;
}


@media screen and (max-width: 700px) {
    .film-title {
        margin-top: 30px!important;
    }

    .film-carousel {
        flex-direction: column;
    }

    .film-carousel-item {
        min-width: 100%;
    }
}
