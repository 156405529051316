.image-block {
    max-width: 260px;
    display: flex;
    position: relative;
}

.button-favorite {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
}
