.carousel {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-color: #a53ba6 transparent;
    scrollbar-width: thin;
    padding-bottom: 10px;
}

.carousel-item {
    margin-right: 15px;
}

.carousel-item:nth-last-child(1) {
    margin-right: 0;
}


@media screen and (max-width: 700px) {
    .carousel-item {
        min-width: auto;
    }
}
